import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import useWindowDimensions from "../Helper/useWidth"
import "./youtube.css"

export default function Youtube() {
  const { width } = useWindowDimensions()
  const [data, setData] = useState([])
  const api = "AIzaSyC0h6xye1PZCMzNa9Q22LhAO3mjFyLZlVM"

  const no = width > 500 ? 6 : width > 800 ? 9 : 4

  const playlistId = "UUGUyz8vlh75eKAudvl3btkQ"
  var h = 1
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&playlistId=${playlistId}&key=${api}`
  var newRes = []
  useEffect(() => {
    const fetchData = async url => {
      const result = await axios(url)
      const items = result.data.items
      newRes = [...newRes, ...items]
      const token = result.data.nextPageToken
      if (h < 2) {
        h = h + 1
        let newUrl = `${url}&pageToken=${token}`
        fetchData(newUrl)
      }
      setData(newRes)
      console.log(result)
    }

    fetchData(url)
  }, [])

  let loadData = data && data.slice(0, no)

  return (
    <div id="video" className="youtube-showcase">
      <div className="section-head">
        <h4>Cinematography</h4>
        <p>
          You can check out our youtube channel to see more videos. Please donot
          forget to suscribe and leave a like and comment to{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCGUyz8vlh75eKAudvl3btkQ"
          >
            StudioThapas
          </a>
          youtube channel.
        </p>
      </div>
      <div className="grid-container youtube-container">
        {loadData &&
          loadData.map((value, index) => {
            const id = value.contentDetails.videoId
            const src = `https://www.youtube.com/embed/${id}?modestbranding=1&autohide=1&showinfo=0`
            // console.log(index);
            const className =
              index === no ? "youtube-item last" : "youtube-item"
            return (
              <div key={id} className={className}>
                <iframe
                  title="youtube"
                  src={src}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            )
          })}
      </div>
      <div className="view">
        <button className="btn-dark">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCGUyz8vlh75eKAudvl3btkQ"
            className="active"
          >
            View More
          </a>
        </button>
      </div>
    </div>
  )
}
